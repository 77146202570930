import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "./pages";
import Login from "./pages/Login";
import LabResults from "./pages/LabResults";

import ProtectedRoutes from "./utils/ProtectedRoutes";

import "./App.css";
import Headerbar from "./components/Headerbar";
import ImagingResult from "./pages/ImagingResult";
import Footer from "./components/Footer";

function App() {
  return (
    <main className="App">
      <div className="min-h-screen flex flex-col">
        <BrowserRouter>
          <header className="App-header">
            <Headerbar />
          </header>
          <Routes>
            <Route path="/login" element={<Login />} />

            <Route element={<ProtectedRoutes />}>
              <Route path="/" element={<Home />} />
              {/* <Route path="/Home" element={<Home />} /> */}
              <Route path="/ImagingResult" element={<ImagingResult />} />
              <Route path="/LabResults" element={<LabResults />} />
            </Route>
          </Routes>
        </BrowserRouter>
        <Footer />
      </div>
    </main>
  );
}

export default App;
