const TestSelectionListItem = ({
  testId,
  testDate,
  isActive,
  onClick,
}: {
  testId: number;
  testDate: Date;
  isActive: boolean;
  onClick: () => void;
}) => {
  const date = new Date(testDate).toLocaleDateString("BG");
  const color = isActive ? "bg-[var(--secondary-color)]" : "bg-gray-100";

  return (
    <button className="flex h-fit" onClick={onClick}>
      <ul
        className={`
          flex w-full
          px-3 py-4 mb-1
          border-b border-black border-opacity-20
          ${color}
          justify-center rounded-sm shadow-md
          ${isActive ? "text-white" : "text-black"}
          hover:bg-[var(--secondary-color)] hover:text-white
          transition-all
        `}
      >
        <div
          className="flex w-full
          flex-row justify-between gap-2"
        >
          <label>
            <span className="text-gray-400">ID </span>
            {testId}
          </label>
          <label>{date}</label>
        </div>
      </ul>
    </button>
  );
};

export default TestSelectionListItem;
