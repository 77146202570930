import { useEffect, useState } from "react";
import { useAuth } from "../contexts/AuthContext";
import { Link, useLocation } from "react-router-dom";
import { IoMdExit } from "react-icons/io";
import { ImExit } from "react-icons/im";

const mbalLogo = "/icons/logo-mbal.png";

const Headerbar = () => {
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const { state, dispatch } = useAuth();
  const location = useLocation();
  // const navigation = useNavigate();

  const handleLogout = () => {
    dispatch({ type: "LOGOUT" });
    // console.log("Logging out...");
    // console.log(state);
    // console.log(state.user);

    // if (state.user === null) {
    //   //   console.log("Logout");
    //   navigation("/");
    // }
  };

  // console.log(location.pathname);

  useEffect(() => {
    setUserLoggedIn(state.user != null);
  }, [state]);

  return (
    <>
      <div
        className="
          relative flex flex-shrink-0 w-full h-20 justify-center bg-white
          after:absolute after:bottom-0 after:w-full after:h-[2px] after:bg-[--primary-color] after:opacity-50
          "
      >
        <div className="container flex w-full items-center justify-between px-10 text-sm font-bold tracking-wider">
          <div className="flex gap-5 items-center">
            <Link to={"/"} className="flex gap-5 items-center">
              <img
                src={mbalLogo}
                alt="MBAL Logo"
                width="54"
                height="54"
                className="flex"
              />
              {/* <div className="text-lg">МБАЛ София</div> */}
            </Link>
          </div>
          {userLoggedIn && (
            <div className="flex text-xs gap-2 items-center">
              {/* <div>{state.user?.email}</div> */}
              {location.pathname === "/LabResults" ? (
                <div className="navbar-link-inactive">ЛАБОРАТОРНИ / LAB.</div>
              ) : (
                <Link to={"/LabResults"} className="navbar-link">
                  ЛАБОРАТОРНИ / LAB.
                </Link>
              )}
              {location.pathname === "/ImagingResult" ? (
                <div className="navbar-link-inactive">ОБРАЗНИ / IMAGING</div>
              ) : (
                <Link to={"/ImagingResult"} className="navbar-link">
                  ОБРАЗНИ / IMAGING
                </Link>
              )}

              <button
                className="navbar-link"
                onClick={handleLogout}
                title="Излез / Exit"
              >
                <ImExit size={16} />
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Headerbar;
