import axios from "axios";

const API_URL_LAB_TESTS =
  "https://revolutionlab-001-site2.dtempurl.com/LabResults/GetLabResults";

const API_URL_IMAGING_TESTS =
  "https://revolutionlab-001-site2.dtempurl.com/ImagingResults/GetImagingResults";

export interface ImagingTest {
  imagingResult: {
    id: number;
    service: string;
    text: string;
    date: Date;
    bcRisk: string;
    pH_ID: string;
  };
  physistDetails: {
    ID: number;
    PH_ID: number;
    PhysicianFullName: string;
  };
}

export interface LabTestResult {
  physistDetails: {
    RequestID: number;
    ExecPhysID: number;
    ExecPhysName: string;
    RequestDateTime: string;
    ReqPhysistID: number;
    ReqPhysistNAme: string;
  };
  labResult: {
    id: number;
    requestID: number;
    serviceID: number;
    activityID: number;
    requestDateTime: Date;
    name: string;
    result: number | null;
    mUnit: string;
    lowLimit: string | null;
    highLimit: string | null;
    patoStateID: number;
    agree: string;
    level: number;
    sOrder: number;
    resOrder: number;
    statusWork: number;
    labExamStatus: number;
    serviceTypeName: string;
    serviceTypeID: number;
    serviceTypeOrder: number;
    labNum: number;
    comment: string;
    finSourceID: number;
    webUserID: number;
  };
}

export const getUserLabTests = async (token: string) => {
  const config = {
    headers: {
      Authorization: token,
    },
  };

  try {
    const response = await axios.get(API_URL_LAB_TESTS, config);
    return response.data;
  } catch (error) {
    console.error("Error fetching lab tests:", error);
    throw error;
  }
};

export const getUserImagingTests = async (token: string) => {
  const config = {
    headers: {
      Authorization: token,
    },
  };

  try {
    const response = await axios.get(API_URL_IMAGING_TESTS, config);
    return response.data;
  } catch (error) {
    console.error("Error fetching imaging test:", error);
    throw error;
  }
};
