import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { api } from "../../api/api";

import { useAuth } from "../../contexts/AuthContext";
import { LabTestResult } from "../../services/userService";
import LabTestTable from "../../components/LabTestTable";
import TestSelectionListItem from "../../components/TestSelectionListItem";

import generatePDF, { usePDF } from "react-to-pdf";
import { ReactToPrint } from "react-to-print";

import { BiSolidFilePdf } from "react-icons/bi";
import { RiPrinterFill } from "react-icons/ri";

const LabResults = () => {
  const { state } = useAuth();
  const navigate = useNavigate();

  const [token] = useState<string | undefined>(state.user?.token);
  const [mobileTestListState, setMobileTestListState] = useState<boolean>(true);
  const [currentLabTestToPreview, setCurrentLabTestToPreview] =
    useState<number>(-1);

  const [filteredLabResults, setFilteredLabResults] = useState<
    LabTestResult[][]
  >([]);

  const [error, setError] = useState<string | any>("");
  const componentRef = useRef<HTMLDivElement>(null);

  const fetctsUserTests = async () => {
    try {
      const result = await api.getUserLabTests(token || "");

      // Group the data by requestID using a Map
      const groupedData = result.reduce(
        (acc: Map<number, LabTestResult[]>, test: LabTestResult) => {
          const requestID = Number(test.labResult.requestID);
          if (!acc.has(requestID)) {
            acc.set(requestID, []);
          }
          acc.get(requestID)!.push(test);
          return acc;
        },
        new Map<number, LabTestResult[]>()
      );

      const sortedKeys: number[] = Array.from(groupedData.keys())
        .map((key) => Number(key))
        .sort((a, b) => b - a);

      const groupedArray: LabTestResult[][] = sortedKeys.map(
        (key) => groupedData.get(key)!
      );

      // console.log(groupedArray);

      setFilteredLabResults(groupedArray);
    } catch (error) {
      // setError(error);
      setError("Error fetching lab tests. Please try again.");
    }
  };

  const handleSetCurrentLabTest = (index: number) => {
    // console.log(currentLabTestToPreview, index);
    if (index !== currentLabTestToPreview) {
      setCurrentLabTestToPreview(index);
    }
  };

  const handleMobileTestListState = () => {
    setMobileTestListState((prevState) => !prevState);
  };

  const downloadPDF = (fileName: string) => {
    generatePDF(componentRef, { filename: fileName });
  };

  const desktopTestEntrisStyle = `
    hidden lg:sticky lg:flex flex-col flex-shrink flex-grow
    mx-2 p-2
    bg-[var(--mid-gray-color)]
    top-0 right-0
    max-w-sm max-h-[600px]
    text-xs font-light
    overflow-y-auto custom-scrollbar`;

  const mobileTestEntrisStyle = `
    bg-[var(--mid-gray-color)]
    p-5 mt-4
    max-h-[400px]
    text-sm font-light
    shadow-lg
    overflow-y-auto custom-scrollbar`;

  const LabelBar = () => {
    return (
      <div>
        {/* <div className="flex w-full p-5 justify-between bg-[var(--primary-color)] rounded text-white mt-10">
          <p className="flex w-full text-start">ЛАБОРАТОРНИ ИЗСЛЕДВАНИЯ</p>

          <button
            className="flex lg:hidden"
            onClick={handleMobileTestListState}
          >
            {mobileTestListState ? "OPEN" : "CLOSE"}
          </button>
        </div> */}
        <TestEntries isMobile={true} />
      </div>
    );
  };

  const TestEntries = ({ isMobile = false }: { isMobile: boolean }) => {
    return (
      <div
        className={isMobile ? mobileTestEntrisStyle : desktopTestEntrisStyle}
      >
        <div
          className="
            flex flex-col w-full
            pr-1
            rounded-md
          "
        >
          <label
            className="
                top-0 px-4 py-3 mb-4 text-white
                bg-[var(--primary-color)]
                rounded-sm border border-gray-400 shadow-md
                "
          >
            Лаб. изследвания / Lab tests
          </label>
          {filteredLabResults &&
            filteredLabResults.map(
              (testGroup: LabTestResult[], index: number) => (
                <TestSelectionListItem
                  key={`test-${index}`}
                  testId={testGroup[0].labResult?.labNum}
                  testDate={testGroup[0].labResult?.requestDateTime}
                  isActive={currentLabTestToPreview === index}
                  onClick={() => handleSetCurrentLabTest(index)}
                />
              )
            )}
        </div>
      </div>
    );
  };

  const LabTestListSkeleton = () => {
    return (
      <>
        <div className="flex flex-col w-full h-full gap-1">
          {Array.from({ length: 10 }).map((_, index) => (
            <div
              key={`skel-item-${index}`}
              className="
                flex w-full h-full m-auto
                bg-gray-600 text-white
                justify-center
                rounded-md
                animate-pulse
                text-3xl
              "
            >
              .....
            </div>
          ))}
        </div>
      </>
    );
  };

  useEffect(() => {
    if (!state.user) {
      navigate("/Login");
    } else {
      fetctsUserTests();
    }
  }, [state]);

  return (
    <>
      <div className="container m-auto px-2">
        {/* {filteredLabResults?.length > 0 ? ( */}
        <>
          {/* PAGE LABEL */}
          <div className="visible flex flex-col lg:hidden">
            {/* <LabelBar /> */}
            {/* <TestEntries isMobile={true} /> */}
            <>
              <>
                <div className={mobileTestEntrisStyle}>
                  <div
                    className="
                      flex flex-col w-full h-full
                      pr-1
                      rounded-md
                    "
                  >
                    <label
                      className="
                        top-0 px-4 py-3 mb-4 text-white
                        bg-[var(--primary-color)]
                        rounded-sm border border-gray-400 shadow-md
                      "
                    >
                      Лаб. изследвания / Lab tests
                    </label>
                    {filteredLabResults?.length > 0 ? (
                      <>
                        {filteredLabResults &&
                          filteredLabResults.map(
                            (testGroup: LabTestResult[], index: number) => (
                              <TestSelectionListItem
                                key={`test-${index}`}
                                testId={testGroup[0].labResult?.labNum}
                                testDate={
                                  testGroup[0].labResult?.requestDateTime
                                }
                                isActive={currentLabTestToPreview === index}
                                onClick={() => handleSetCurrentLabTest(index)}
                              />
                            )
                          )}
                      </>
                    ) : (
                      <LabTestListSkeleton />
                    )}
                    ;
                  </div>
                </div>
              </>
            </>
          </div>

          {/* LAB TESTS SECTION */}
          <div className="flex mt-10 justify-start">
            {/* A4 DOCUMENT */}
            <div className="relative page text-xs">
              {/* PRINT AND DOWNLOAD */}
              {currentLabTestToPreview >= 0 && (
                <div className="absolute right-[calc(30px)] pt-2 flex gap-2">
                  {/* Download PDF */}
                  {/* <button onClick={() => downloadPDF("page.pdf")}>
                      <BiSolidFilePdf
                        size={28}
                        className=" p-1 bg-black text-black rounded-sm hover:bg-[var(--secondary-color)] hover:text transition-all"
                        color="white"
                        title="Изтегли PDF / Download PDF"
                      />
                    </button> */}
                  {/* Print Document */}
                  <ReactToPrint
                    trigger={() => {
                      return (
                        <button>
                          <RiPrinterFill
                            size={28}
                            className=" p-1 bg-black text-black rounded-sm hover:bg-[var(--secondary-color)] hover:text transition-all"
                            color="white"
                            title="Принтирай / Print"
                          />
                        </button>
                      );
                    }}
                    content={() => componentRef.current} // Use the ref here
                  />
                  {/* <ComponentToPrint ref={(el) => (this.componentRef = el)} /> */}
                </div>
              )}

              {/* SELECTED TEST TABLE */}
              <div ref={componentRef} className="p-[0mm]">
                {currentLabTestToPreview >= 0 ? (
                  <div className="flex w-full items-start">
                    <LabTestTable
                      labTest={filteredLabResults[currentLabTestToPreview]}
                      user={state.user}
                    />
                  </div>
                ) : (
                  <div className="flex w-full justify-center opacity-80 text-lg">
                    <div className="flex flex-col text-center">
                      <p>изберете изследване от таблицата за детаили...</p>
                      <p>select a test from the table for details...</p>
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* TEST ENTRY LIST - DESKTOP */}
            {/* <TestEntries isMobile={false} /> */}
            {/* <div className="hidden lg:inline-flex lg:w-auto">
              </div> */}
            <>
              <div className={desktopTestEntrisStyle}>
                <div
                  className="
                      flex flex-col w-full h-full
                      pr-1
                      rounded-md
                    "
                >
                  <label
                    className="
                        top-0 px-4 py-3 mb-4 text-white
                        bg-[var(--primary-color)]
                        rounded-sm border border-gray-400 shadow-md
                      "
                  >
                    Лаб. изследвания / Lab tests
                  </label>
                  {filteredLabResults?.length > 0 ? (
                    <>
                      {filteredLabResults &&
                        filteredLabResults.map(
                          (testGroup: LabTestResult[], index: number) => (
                            <TestSelectionListItem
                              key={`test-${index}`}
                              testId={testGroup[0].labResult?.labNum}
                              testDate={testGroup[0].labResult?.requestDateTime}
                              isActive={currentLabTestToPreview === index}
                              onClick={() => handleSetCurrentLabTest(index)}
                            />
                          )
                        )}
                    </>
                  ) : (
                    <LabTestListSkeleton />
                  )}
                  ;
                </div>
              </div>
            </>
          </div>
          {error && <div>{error}</div>}
        </>
      </div>
    </>
  );
};

export default LabResults;
