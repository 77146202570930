import React, { useState } from "react";

const Footer = () => {
  //   const [yaer, SetYear] = useState("");

  const year = new Date().getFullYear();

  return (
    <>
      <div
        className="sticky bottom-0 flex w-full h-10
        bg-[var(--primary-color)] text-white
        justify-center items-center
        border-t-4 border-[var(--secondary-color)]"
      >
        <div className="text-sm font-extralight">© {year} Copyright</div>
      </div>
    </>
  );
};

export default Footer;
