import React from "react";
import { LabTestResult } from "../services/userService";
import { User } from "../contexts/AuthContext";
const mbalLogo = "/icons/logo-mbal-2.png";
const imgDrSign = "/sign-dr.jpg";

const convertToDate = (dateTime: string) => {
  return (
    new Date(dateTime)
      .toLocaleString("bg-BG", {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: undefined,
        hour12: false,
      })
      .replace(/\sг\./, "") || "-----"
  );
};

const LabTestHeader = ({
  personName,
  EGN,
  labNum,
  ExecPhysName,
  physistRequestDateTime,
  labTestRequestDateTime,
}: {
  personName: string;
  EGN: string;
  labNum: number;
  ExecPhysName: string;
  physistRequestDateTime: string;
  labTestRequestDateTime: string;
}) => {
  return (
    /* SELECTED TEST HEADER */
    <div className="flex flex-col w-full h-fit bg-gray-100 border border-gray-400 mb-3">
      <div className="flex w-full p-4">
        {mbalLogo ? (
          <img
            src={mbalLogo}
            alt="MBAL Logo"
            className="flex w-32 h-32 object-contain"
          />
        ) : (
          <div className="flex w-32 h-32 object-contain bg-gray-500" />
        )}
        <div className="flex flex-col w-full text-base text-center font-bold text-black px-10 rounded-md">
          <h2 className="flex flex-col h-full justify-center">
            <span>ДКЦ "СВЕТА СОФИЯ" ЕООД</span>
            <span>Гр. София, бул. "България" 104</span>
            <span>тел. 02/81 84 600, факс 02/81 84 666</span>
          </h2>
        </div>
      </div>
      <div className="flex w-full h-fit justify-center text-lg font-bold border-b border-t border-gray-400">
        РЕЗУЛТАТИ ОТ ЛАБОРАТОРНИ ИЗСЛЕДВАНИЯ
      </div>

      <div className="grid grid-flow-row grid-cols-12">
        <div className="flex col-span-12">
          {/* PATIENT */}
          <div className="flex flex-col w-1/2 border-b border-r border-gray-400">
            <div className="flex w-1/2 h-fit p-1 font-bold">Пациент</div>
            <div className="grid grid-cols-6 col-span-6 p-1">
              {/* NAME */}
              <div className="col-span-4 flex gap-1">
                <div className="font-bold">Име:</div>
                <div>{personName}</div>
              </div>
              {/* EGN */}
              <div className="col-span-2 flex gap-1 justify-end">
                <div className="font-bold">ЕНГ:</div>
                <div>{EGN}</div>
              </div>
            </div>
          </div>

          <div className="flex flex-col w-1/2 border-b border-gray-400">
            <div className="flex w-1/2 h-fit p-1 font-bold">Отделение</div>
            <div className="flex w-full p-1">КЛИНИЧНА ЛАБОРАТОРИЯ</div>
          </div>
        </div>

        <div className="flex col-span-12">
          {/* TEST AND NUMBER */}
          <div className="flex flex-col w-1/2 border-r border-gray-400">
            <div className="flex p-1 font-bold">Изследване</div>
            <div className="flex justify-between p-1">
              <div className="flex gap-1">
                <div>
                  <span className="font-bold">N:</span> {labNum}
                </div>
              </div>
              <div className="flex gap-1">
                <div>
                  <span className=" font-bold">Дата: </span>
                  {convertToDate(physistRequestDateTime)}
                </div>
              </div>
            </div>
          </div>

          {/* SENDING DOCTOR */}
          <div className="flex flex-col w-1/2">
            <div className="flex p-1 font-bold">Изпращащ лекар</div>
            <div className="flex justify-between p-1">
              <div className="flex gap-1">
                <span className="font-bold">Име: </span>
                {ExecPhysName ? ExecPhysName : "Д-р -----"}
              </div>
              <div className="flex gap-1">
                <span className="font-bold">Дата:</span>
                <span className="whitespace-nowrap">
                  {convertToDate(labTestRequestDateTime)}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const getPatoState = (patoStateID: number) => {
  switch (patoStateID) {
    case 0:
      return "-";
    case 1:
      return "L";
    case 2:
      return "H";
    default:
      return "";
  }
};

const LabTestGruop = ({ name }: { name: string }) => {
  return (
    <tr className="flex w-full text-[10px] border-t border-gray-300">
      <td
        className={
          "w-[50%] bg-[var(--light-gray-color)] px-1 py-1 text-gray-500 border-r border-[var(--light-gray-color)]"
        }
      >
        {name}
      </td>
      <td className="w-[10%] px-1 py-1 text-gray-500 border-r border-gray-300" />
      <td className="w-[10%] px-1 py-1 text-gray-500 border-r border-gray-300" />
      <td className="w-[10%] px-1 py-1 text-gray-500 border-r border-gray-300" />
      <td className="w-[10%] px-1 py-1 text-gray-500 border-r border-gray-300" />
      <td className="w-[10%] px-1 py-1 text-gray-500 border-r border-gray-300" />
    </tr>
  );
};

const LabTestData = ({ data }: { data: LabTestResult }) => {
  let result: number = data.labResult.result || -1;

  result = Math.fround(result);

  return (
    <tr className="flex w-full text-[10px] border-t border-gray-300">
      <td
        className={
          data.labResult.result
            ? "w-[50%] px-6 py-1 text-gray-500 border-r border-gray-300"
            : "w-[50%] px-3 bg-gray-200 py-1 text-gray-500 border-r border-[var(--light-gray-color)]"
        }
      >
        {data.labResult.name}
      </td>
      <td className="w-[10%] px-1 py-1 text-gray-500 border-r border-gray-300">
        {result >= 0 ? result.toFixed(2) : ""}
      </td>
      <td className="w-[10%] px-1 py-1 text-gray-500 border-r border-gray-300">
        {data.labResult.mUnit === "NULL" ? "" : data.labResult.mUnit}
      </td>
      <td className="w-[10%] px-1 py-1 text-gray-500 border-r border-gray-300">
        {data.labResult.lowLimit === "NULL" ? "" : data.labResult.lowLimit}
      </td>
      <td className="w-[10%] px-1 py-1 text-gray-500 border-r border-gray-300">
        {data.labResult.highLimit === "NULL" ? "" : data.labResult.highLimit}
      </td>
      <td className="w-[10%] px-1 py-1 text-gray-500 border-r border-gray-300">
        {data.labResult.highLimit === "NULL"
          ? ""
          : getPatoState(data.labResult.patoStateID)}
      </td>
    </tr>
  );
};

// interface Data {
//   // name: string;
//   ids: number[];
// }

const groupData = (labTest: LabTestResult[]): Map<string, number[]> => {
  const groupedData = labTest.reduce(
    (acc: Map<string, number[]>, test: LabTestResult) => {
      const serviceTypeName = test.labResult.serviceTypeName;
      if (!acc.has(serviceTypeName)) {
        acc.set(serviceTypeName, []);
      }
      return acc;
    },

    new Map<string, number[]>()
  );

  groupedData.forEach((_dataArray, serviceTypeName) => {
    const ids: number[] = labTest
      .filter((t) => t.labResult.serviceTypeName === serviceTypeName)
      .map((t) => t.labResult.id);
    // console.log(serviceTypeName);
    // console.log(ids);
    groupedData.get(serviceTypeName)!.push(...ids);
  });

  return groupedData;
};

const LabTestTable = ({
  labTest,
  user,
}: {
  labTest: LabTestResult[];
  user: User | null;
}) => {
  const groupedData = groupData(labTest);

  return (
    <div className="flex flex-col w-full">
      <LabTestHeader
        personName={user?.data.PersonName || ""}
        EGN={user?.data.EGN || ""}
        labNum={labTest[0].labResult.labNum || 0}
        ExecPhysName={labTest[0].physistDetails.ReqPhysistNAme || ""}
        physistRequestDateTime={labTest[0].physistDetails.RequestDateTime || ""}
        labTestRequestDateTime={labTest[0].labResult.requestDateTime.toLocaleString()}
      />
      <table className="flex flex-col w-full bg-white border border-gray-300">
        <thead className="">
          <tr className="flex w-full">
            <th className="w-[50%]">Група/Показател</th>
            <th className="w-[10%]">Резултат</th>
            <th className="w-[10%]">Единици</th>
            <th className="w-[10%]">Долна гр.</th>
            <th className="w-[10%]">Горна гр.</th>
            <th className="w-[10%]">Патолог.</th>
          </tr>
        </thead>
        <tbody className="bg-white">
          {Array.from(groupedData.entries()).map(
            ([serviceTypeName, ids], index) => (
              <React.Fragment key={`group-fragment-${index}`}>
                <LabTestGruop key={`group-${index}`} name={serviceTypeName} />
                {ids.map((_id, _idIndex) => {
                  const test = labTest.find(
                    (item) => item.labResult.id === _id
                  );
                  if (test) {
                    return (
                      <LabTestData key={`entry-${_idIndex}`} data={test} />
                    );
                  }
                  return null; // Return null for missing tests
                })}
              </React.Fragment>
            )
          )}
        </tbody>
      </table>

      {/* PATO STATE NOTES */}
      <div className="flex flex-col pt-5">
        <div className="flex gap-1">
          <div className="w-14 me-5">Коментар:</div>
          <div className="flex gap-1">
            <span className="font-bold">L</span> - Резултатът е под долна
            граница
          </div>
        </div>
        <div className="flex gap-1">
          <div className="w-14 me-5"></div>
          <div className="flex gap-1">
            <span className="font-bold">H</span> - Резултатът е над горна
            граница
          </div>
        </div>
      </div>

      {/* NOTE */}
      <div className="flex w-full max-w-xl self-center text-center mt-10 items-center">
        Резултатите от изследванията не са диагноза, те трябва да бъдат
        тълкувани от Лекуващия лекар в контекста на общото състояние на болния
      </div>

      <div className="flex flex-col text-end mt-20 mr-20 break-inside-avoid">
        <div>/{labTest[0].physistDetails.ExecPhysName}/</div>
        <img
          src={imgDrSign}
          className="self-end w-1/2 border border-gray-300"
        />
      </div>
    </div>
  );
};

export default LabTestTable;
