import React, {
  createContext,
  useReducer,
  useContext,
  ReactNode,
  useEffect,
} from "react";

import { jwtDecode } from "jwt-decode";

export type UserData = {
  EGN: string;
  PersonID: string;
  PersonName: string;
};

export type User = {
  token: string | undefined;
  expiration: string;
  data: UserData;
};

type AuthState = {
  user: User | null;
};

const initialState: AuthState = {
  user: null,
};

type AppAction = { type: "LOGIN"; payload: User } | { type: "LOGOUT" };

const appReducer = (state: AuthState, action: AppAction): AuthState => {
  switch (action.type) {
    case "LOGIN":
      return { ...state, user: action.payload };
    case "LOGOUT":
      return { ...state, user: null };
    default:
      return state;
  }
};

const AuthContext = createContext<
  | {
      state: AuthState;
      dispatch: React.Dispatch<AppAction>;
    }
  | undefined
>(undefined);

const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(appReducer, initialState);

  useEffect(() => {
    // console.log("AUTH : useEffect - init");
    const storedUser = localStorage.getItem("user");
    if (storedUser) {
      dispatch({ type: "LOGIN", payload: JSON.parse(storedUser) });
    }
  }, []);

  useEffect(() => {
    // Decoding token

    if (state.user) {
      const token = state.user?.token;

      if (token) {
        const decodedToken = jwtDecode<UserData>(token);
        state.user.data = decodedToken;
        // console.log("USER DATA...");
        // console.log(state.user.data);
      }

      localStorage.setItem("user", JSON.stringify(state.user));
    } else {
      localStorage.removeItem("user");
    }
  }, [state.user]);

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useApp must be used within an AuthProvider");
  }
  return context;
};

export { AuthProvider, useAuth };
