import { useEffect, useRef, useState } from "react";
import { ImagingTest } from "../../services/userService";

import { api } from "../../api/api";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import TestSelectionListItem from "../../components/TestSelectionListItem";
import { BiSolidFilePdf } from "react-icons/bi";
import { RiPrinterFill } from "react-icons/ri";
import ReactToPrint from "react-to-print";
import generatePDF from "react-to-pdf";

import { calculateAgeFromEGNOnDate } from "../../utils/Misc";

const mbalLogo = "/icons/logo-mbal-2.png";
const iconEU = "/icon-eu.png";
const iconNsrr = "/icon-nsrr.png";
const iconRkbi = "/icon-rkbi.png";
const iconImagingFooter = "/icon-imaging-footer.jpg";

const ImagingResult = () => {
  const { state } = useAuth();
  const navigate = useNavigate();

  const [tests, setTests] = useState<ImagingTest[]>([]);
  const [token] = useState<string | undefined>(state.user?.token);
  const [mobileTestListState, setMobileTestListState] = useState<boolean>(true);
  const [currentLabTestToPreview, setCurrentLabTestToPreview] =
    useState<number>(-1);

  const componentRef = useRef<HTMLDivElement>(null);

  const fetchImagingResultData = async () => {
    try {
      const result = await api.getUserImagingTests(token || "");
      // console.log(result);
      const sortedList = result.sort(
        (a: ImagingTest, b: ImagingTest) =>
          b.imagingResult.id - a.imagingResult.id
      );
      // console.log(sortedList);
      setTests(sortedList);
    } catch (error) {
      console.log(error);
    }
  };

  const handleSetCurrentLabTest = (index: number) => {
    if (index !== currentLabTestToPreview) {
      setCurrentLabTestToPreview(index);
    }
  };

  useEffect(() => {
    if (!state.user) {
      navigate("/Login");
    } else {
      fetchImagingResultData();
    }
  }, [state]);

  const downloadPDF = (fileName: string) => {
    generatePDF(componentRef, { filename: fileName });
  };

  const desktopTestEntrisStyle = `
    hidden lg:sticky lg:flex flex-col flex-grow
    mx-2 p-2
    bg-[var(--mid-gray-color)]
    top-0 right-0
    max-w-sm max-h-[600px]
    text-xs font-light
    overflow-y-auto custom-scrollbar`;

  const mobileTestEntrisStyle = `
    bg-[var(--mid-gray-color)]
    p-5 mt-4
    max-h-[400px]
    text-sm font-light
    shadow-lg
    overflow-y-auto custom-scrollbar`;

  // const ImagingData = ({ data }: { data: ImagingTest }) => {
  //   return (
  //     <tr className="border-t border-gray-300">
  //       <td className="px-4 py-2 text-sm text-gray-500 border-r border-gray-300">
  //         <div>{data.service}</div>
  //         <div className="mt-4 text-sm font-bold">
  //           {data.date.toLocaleString()}
  //         </div>
  //       </td>
  //       <td className="px-4 py-4 text-sm text-gray-500 border-r border-gray-300">
  //         {data.text}
  //       </td>
  //       <td className="px-4 py-4 text-sm text-gray-500 border-r border-gray-300">
  //         {data.bcRisk === "NULL" ? "" : data.bcRisk}
  //       </td>
  //       <td className="px-4 py-4 text-sm text-gray-500 border-r border-gray-300">
  //         {data.pH_ID === "NULL" ? "" : data.pH_ID}
  //       </td>
  //     </tr>
  //   );
  // };

  // const ImagingTable = () => {
  //   return (
  //     <table className="container bg-white border border-gray-300">
  //       <thead>
  //         <tr className="">
  //           {/* <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
  //             ID
  //           </th> */}
  //           <th>Тип</th>
  //           <th>Резултат</th>
  //           <th className="min-w-20">Риск</th>
  //           <th className="min-w-20">PH</th>
  //         </tr>
  //       </thead>
  //       <tbody className="bg-white">
  //         {tests.map((item, index) => {
  //           return <ImagingData key={`entry-${index}`} data={item} />;
  //         })}
  //       </tbody>
  //     </table>
  //   );
  // };

  const FooterInfoGroup = () => {
    return (
      <>
        {/* GROUP 1 */}
        <div
          className="flex flex-col w-1/3 h-full p-2 gap-2 items-center text-center
      border-r border-gray-300
      leading-3"
        >
          <img src={iconEU} width={64} height={64} />
          <div>
            ЕВРОПЕЙСКИ СЪЮЗ <br /> Европейски фонд за регионално развитие <br />{" "}
            Инвестираме във вашето бъдеще
          </div>
        </div>

        {/* GROUP 2 */}
        <div
          className="flex w-1/3 h-full justify-center p-2
      border-r border-gray-300"
        >
          <img
            src={iconNsrr}
            width={128}
            height={128}
            className="object-contain self-start"
          />
        </div>

        {/* GROUP 3 */}
        <div className="flex flex-col w-1/3 h-full p-2 gap-2 items-center text-center">
          <img src={iconRkbi} width={64} height={64} />
          <div className="leading-3">
            <span>ОПЕРАТИВНА ПРОГРАМА</span>
            <br />
            <span>
              „Развитие на конкурентоспособността на българската икономика”
              2007-2013
            </span>
            <br />
            <span>
              <a href="https://www.opcompetitiveness.bg/" target="_blank">
                www.opcompetitiveness.bg
              </a>
            </span>
          </div>
        </div>
      </>
    );
  };

  const LabTestListSkeleton = () => {
    return (
      <>
        <div className="flex flex-col w-full h-full gap-1">
          {Array.from({ length: 10 }).map((_, index) => (
            <div
              key={`skel-item-${index}`}
              className="
                flex w-full h-full m-auto
                bg-gray-600 text-white
                justify-center
                rounded-md
                animate-pulse
                text-3xl
              "
            >
              .....
            </div>
          ))}
        </div>
      </>
    );
  };

  const tryRemoveDateFromText = (text: string) => {
    const dateIndex = text.indexOf("Дата: ");
    const cleanedText =
      dateIndex !== -1
        ? tests[currentLabTestToPreview].imagingResult.text.slice(0, dateIndex)
        : text;
    return cleanedText;
  };

  return (
    <div className="container m-auto px-2">
      <>
        {/* PAGE LABEL */}
        <div className="visible flex flex-col lg:hidden">
          {/* IMAGING TEST LIST - MOBILE */}
          <div className={mobileTestEntrisStyle}>
            <div
              className="
                flex flex-col w-full
                pr-2 gap-1
                rounded-md
                "
            >
              <label
                className="sticky top-0 px-4 py-3 mb-4 text-white
                  bg-[var(--primary-color)]
                  rounded-sm border border-gray-400 shadow-md"
              >
                Образни изследвания / Imaging tests
              </label>
              {tests?.length > 0 ? (
                <>
                  {tests.length > 0 &&
                    tests.map((test, index) => {
                      return (
                        <TestSelectionListItem
                          key={`test-${index}`}
                          testId={test.imagingResult.id}
                          testDate={test.imagingResult.date}
                          isActive={currentLabTestToPreview === index}
                          onClick={() => handleSetCurrentLabTest(index)}
                        />
                      );
                    })}
                </>
              ) : (
                <LabTestListSkeleton />
              )}
              ;
            </div>
          </div>
        </div>
        {/* LAB TESTS SECTION */}
        <div className="relative flex mt-10 justify-start">
          {/* PRINT AND DOWNLOAD BUTTONS */}
          <>
            {/* <div className="absolute left-auto right-0 flex w-full gap-2">
            <button className="p-1 rounded-sm bg-green-500">PRI</button>
            <button className="p-1 rounded-sm bg-green-500">PDF</button>
            </div> */}
          </>
          {/* A4 DOCUMENT */}
          <div className="relative page text-xs">
            {/* PRINT AND DOWNLOAD */}
            {currentLabTestToPreview >= 0 && (
              <div className="absolute right-[calc(30px)] pt-2 flex gap-2">
                {/* Download PDF */}
                {/* <button onClick={() => downloadPDF("page.pdf")}>
                  <BiSolidFilePdf
                    size={28}
                    className=" p-1 bg-black text-black rounded-sm hover:bg-[var(--secondary-color)] hover:text transition-all"
                    color="white"
                    title="Изтегли PDF / Download PDF"
                  />
                </button> */}
                {/* Print Document */}
                <ReactToPrint
                  trigger={() => {
                    return (
                      <button>
                        <RiPrinterFill
                          size={28}
                          className=" p-1 bg-black text-black rounded-sm hover:bg-[var(--secondary-color)] hover:text transition-all"
                          color="white"
                          title="Принтирай / Print"
                        />
                      </button>
                    );
                  }}
                  content={() => componentRef.current} // Use the ref here
                />
                {/* <ComponentToPrint ref={(el) => (this.componentRef = el)} /> */}
              </div>
            )}

            {/* SELECTED TEST TABLE */}
            <div ref={componentRef} className="p-[0mm]">
              {currentLabTestToPreview >= 0 ? (
                <>
                  <div className="flex flex-col w-full h-fit bg-gray-100 border border-gray-400 mb-3">
                    <div className="flex w-full p-4">
                      <img
                        src={mbalLogo}
                        alt="MBAL Logo"
                        className="flex w-32 h-32 object-contain"
                      />
                      <div className="flex flex-col w-full text-base text-center font-bold text-black px-5 rounded-md">
                        <h2 className="flex flex-col h-full justify-center">
                          <span>
                            МНОГОПРОФИЛНА БОЛНИЦА ЗА АКТИВНО ЛЕЧЕНИЕ{" "}
                            <span className="whitespace-nowrap">
                              “СВЕТА СОФИЯ”
                            </span>
                          </span>

                          <span>
                            гр. София 1618, бул. „България” № 104,{" "}
                            <span className="whitespace-nowrap">
                              тел. 02/81 84 630
                            </span>
                          </span>
                        </h2>
                      </div>
                    </div>
                  </div>
                  <div className="flex h-fit justify-center text-base text-center font-bold py-10">
                    РЕЗУЛТАТ ОТ ОБРАЗНО – ДИАГНОСТИЧНО ИЗСЛЕДВАНЕ ОТ ОТДЕЛЕНИЕ
                    ПО ОБРАЗНА ДИАГНОСТИКА
                  </div>
                  <div className="flex h-fit py-1 font-bold">
                    Пациент:{" "}
                    <div className="pl-1">{state.user?.data.PersonName}</div>,
                    <div className="pl-1">
                      {state.user?.data.EGN
                        ? calculateAgeFromEGNOnDate(
                            state.user?.data.EGN,
                            new Date(
                              tests[currentLabTestToPreview].imagingResult.date
                            )
                          ) + "г."
                        : ""}
                      {", "}
                      Рег. номер - {state.user?.data.PersonID}
                    </div>
                  </div>
                  <div className="flex h-fit py-1 font-bold mt-5">
                    {/* Koмпютъртомографско изследване */}
                    {tests[currentLabTestToPreview].imagingResult.service}
                  </div>
                  <div className="flex flex-col h-fit py-5 gap-2 leading-4">
                    {tryRemoveDateFromText(
                      tests[currentLabTestToPreview].imagingResult.text
                    )}

                    {/* NOTE : attempt to split imagingResult.text */}
                    {/* SPLIT TEXT */}
                    {/* {tests[currentLabTestToPreview].text
                      // .split(/(?<!\b[А-Я]{,})\s+(?=[А-Я])/g)
                      .split("  ")
                      .map((item: string, id) => (
                        <div
                          key={id}
                          className={`
                              ${
                                item.includes(
                                  "Koмпютъртомографско изследване"
                                ) ||
                                item.includes("КТ находка:") ||
                                item.includes("Торакс:") ||
                                item.includes("Торакс:") ||
                                item.toUpperCase().includes("ЗАКЛЮЧЕНИЕ:")
                                  ? "text-base font-extrabold pt-4"
                                  : ""
                              }`}
                        >
                          {item || <br />}
                        </div>
                      ))} */}
                  </div>
                  <div className="flex h-fit py-1 font-bold">
                    {
                      tests[currentLabTestToPreview].physistDetails
                        .PhysicianFullName
                    }
                  </div>
                  <div className="flex h-fit py-1 font-bold">
                    {new Date(
                      tests[currentLabTestToPreview].imagingResult.date
                    ).toLocaleDateString("BG")}
                  </div>

                  {/* INFO GROUP */}
                  <div className="flex mt-10">
                    {/* <FooterInfoGroup /> */}
                    <img src={iconImagingFooter} />
                  </div>
                </>
              ) : (
                <div className="flex w-full justify-center opacity-80 text-lg">
                  <div className="flex flex-col text-center">
                    <p>изберете изследване от таблицата за детаили...</p>
                    <p>select a test from the table for details...</p>
                  </div>
                </div>
              )}
            </div>
          </div>

          {/* IMAGING TEST LIST - DESKTOP */}
          <div className={desktopTestEntrisStyle}>
            <div
              className="
                flex flex-col w-full h-full
                pr-2 gap-1
                rounded-md
                "
            >
              <label
                className="sticky top-0 px-4 py-3 mb-4 text-white
                  bg-[var(--primary-color)]
                  rounded-sm border border-gray-400 shadow-md"
              >
                Образни изследвания / Imaging tests
              </label>
              {tests?.length > 0 ? (
                <>
                  {tests.length > 0 &&
                    tests.map((test, index) => {
                      return (
                        <TestSelectionListItem
                          key={`test-${index}`}
                          testId={test.imagingResult.id}
                          testDate={test.imagingResult.date}
                          isActive={currentLabTestToPreview === index}
                          onClick={() => handleSetCurrentLabTest(index)}
                        />
                      );
                    })}
                </>
              ) : (
                <LabTestListSkeleton />
              )}
              ;
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default ImagingResult;
