import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { api } from "../../api/api";
import { useAuth } from "../../contexts/AuthContext";

const MAIL_REGEX = /^[\w-.]+@([\w-]+\.)+[A-Za-z]{2,}$/;
const PASS_REGEX =
  /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&.#])[A-Za-z\d@$!%*?&.#]{6,}$/;

const TEMP_USER: string = "";
const TEMP_PASS: string = "";

const Login = () => {
  // console.log("Login Component Rendered...");

  const [loginName, setLoginName] = useState(TEMP_USER);
  const [loginPass, setLoginPass] = useState(TEMP_PASS);
  const [isLoading, setIsLoading] = useState(false);
  const [incorrectLogin, setIncorrectLogin] = useState(false);

  const nameRef = useRef<HTMLInputElement>(null);
  const passRef = useRef<HTMLInputElement>(null);
  const submitBtnRef = useRef<HTMLButtonElement>(null);

  const { state, dispatch } = useAuth();
  const navigate = useNavigate();

  const validateUserName = (value: string) => {
    setLoginName(value);
    // if (nameRef.current) {
    //   if (MAIL_REGEX.test(value) === false) {
    //     nameRef.current.style.backgroundColor = "orange";
    //   } else {
    //     nameRef.current.style.backgroundColor = "white";
    //   }
    // }
    if (nameRef.current) {
      nameRef.current.style.backgroundColor = "white";
    }
  };

  const validateUserPass = (value: string) => {
    const pass = value;
    setLoginPass(pass);
    if (passRef.current) {
      if (PASS_REGEX.test(pass) === false && passRef.current.value != "") {
        passRef.current.style.backgroundColor = "orange";
      } else {
        passRef.current.style.backgroundColor = "white";
      }
    }
  };

  const handleLogin = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const name = loginName || "";
      const pass = loginPass || "";

      const validCredentials = true; //MAIL_REGEX.test(name); // && PASS_REGEX.test(pass);

      if (validCredentials) {
        const currenUser = await api.loginUser({
          UserName: name,
          Password: pass,
        });

        if (currenUser != null) {
          dispatch({ type: "LOGIN", payload: currenUser });
        } else {
          console.error("Incorrect credentials.");
          setIncorrectLogin(true);
        }
      }
    } catch (error) {
      console.error("Login error:", error);
      setIncorrectLogin(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // console.log("LOGIN : useEffect");
    if (state.user) {
      // console.log(state.user);
      navigate("/");
    }
  });

  const Spinner = () => <div className="spinner"></div>;

  return (
    // <div className="flex-1 flex items-center justify-center bg-gray-100">
    <div className="flex m-auto justify-center items-center">
      <form
        key={"login-form"}
        onSubmit={handleLogin}
        className="flex flex-col bg-white min-w-[360px] w-full max-w-md p-8
             text-black gap-4 text-lg border border-gray-200 rounded-lg shadow-md"
      >
        {/* <!-- Form Title --> */}
        <div className="text-center text-2xl text-[var(--primary-color)] mb-2">
          Потребителски вход / Login
        </div>

        {/* <!-- Email Label & Input --> */}
        <label className="font-medium text-gray-700" htmlFor="loginName">
          ИД / ID
        </label>
        <input
          required
          id={"loginName"}
          ref={nameRef}
          value={loginName}
          disabled={isLoading}
          type="text"
          placeholder="Въведете ИД / Enter ID"
          autoComplete="email"
          onChange={(e) => validateUserName(e.target.value)}
          className="p-2 border border-gray-300 rounded-md focus:outline-none 
                  focus:ring-2 focus:ring-[var(--secondary-color)] focus:border-transparent"
        />

        {/* <!-- Password Label & Input --> */}
        <label className="font-medium text-gray-700" htmlFor="loginPass">
          Парола / Password
        </label>
        <input
          required
          id={"loginPass"}
          // ref={passRef}
          type="password"
          value={loginPass}
          disabled={isLoading}
          placeholder="Парола / Password"
          autoComplete="current-password"
          onChange={(e) => validateUserPass(e.target.value)}
          className="p-2 border border-gray-300 rounded-md focus:outline-none 
                  focus:ring-2 focus:ring-[var(--secondary-color)] focus:border-transparent"
        />

        {/* <!-- Submit Button --> */}
        <div className="flex w-full">
          <button
            type="submit"
            ref={submitBtnRef}
            disabled={isLoading}
            className={`relative flex w-full justify-center py-2 px-4 rounded-lg text-white transition-colors
                ${
                  isLoading
                    ? "bg-slate-700 cursor-not-allowed"
                    : "bg-[var(--primary-color)] hover:bg-[var(--secondary-color)]"
                }`}
          >
            {isLoading ? (
              <div className="flex items-center">
                <div className="absolute left-5">
                  <Spinner />
                </div>
                <div>Зареждане / Loading</div>
              </div>
            ) : (
              "Вход / Login"
            )}
            {/* {isLoading ? "Зареждане..." : "Влез в акаунт"} */}
            {/* {!isLoading ? "" : "Влез в акаунт"} */}
            {/* {!isLoading && (
                  // <div className="flex w-full h-full">
                  // </div>
                  <AiOutlineLoading3Quarters className="absolute flex w-full h-full text-white" />
                )} */}
          </button>
        </div>

        {/* <!-- Error Message --> */}
        {incorrectLogin && (
          <span className="text-red-600 text-sm">
            Грешни имейл или парола / Wrong credentials
          </span>
        )}

        {/* <!-- Forgot Password Link --> */}
        {/* <div className="flex justify-end">
              <a
                href="#"
                className="text-[var(--link-color)] hover:text-[var(--secondary-color)] text-sm underline"
                onClick={() => handleForgotPassword("forgotPass")}
              >
                Забравена парола ?
              </a>
            </div> */}
      </form>
    </div>
  );
};

export default Login;
