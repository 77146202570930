// import React from "react";

import { Outlet, Navigate } from "react-router-dom";
import { useAuth } from "../contexts/AuthContext";

const ProtectedRoutes = () => {
  const { state } = useAuth();

  return state.user ? <Outlet /> : <Navigate to={"/Login"} />;
};

export default ProtectedRoutes;
